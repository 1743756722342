import React, {useState} from "react"
import { Link } from "@StarberryUtils";
import {Container, Row,Col} from 'react-bootstrap'

import HTMLReactParser from 'html-react-parser';
import videoImgMobile from "../../../images/propertydetails/video-img-mobile.jpg"
import videoImgTable from "../../../images/propertydetails/video-img-table.jpg"
import videoImgDesktop from "../../../images/propertydetails/video-img-desktop.jpg"
import PlayVideo from "../../Play/PlayVideo";
import "./VideoArea.scss"
import { AreaGuideDetails } from "../../../queries/common_use_query"
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import { removeDataAttributes } from '../../../comQueryStructure'
// styles

// markup
const VideoArea = (props) => {
  const [isPlay,setPlay] = useState(false);

  // 
  let path_name = (props.property_details_area_guide).split(', ');
  let get_url = path_name[0];
  const {loading, error, data} = AreaGuideDetails('', get_url);
  const areaGuideComm = removeDataAttributes(data?.communities)

  var area_guide = areaGuideComm?.length > 0 && areaGuideComm[0] ? areaGuideComm[0] : ''
  //

  // ggfx
  let processedImages = JSON.stringify({});
  if (area_guide && area_guide.imagetransforms && area_guide.imagetransforms.Tile_Image_Transforms) {
      processedImages = area_guide.imagetransforms.Tile_Image_Transforms;
  }
  // ggfx
  return (
    area_guide &&
    <React.Fragment>
      <div className="video-area-block"> 
        <Container>
          <Row>
            <Col xl={6} className="d-flex align-items-stretch">
               <div className="animated"> 
                <div className="video-area-img img-zoom">
                    <picture>
                      <ImageModule ImageSrc={area_guide.Tile_Image} imagename={"community.Tile_Image.property_details"} article_id={area_guide.id} imagetransforms={props.imagetransforms} ggfxNew={true} />
                      {/* <strong className="play-btn-icon" onClick = { e => {setPlay(true)}}>
                        <i className="icon-play-btn"></i>
                      </strong> */}
                    </picture>
                    {/* <picture className="">
                        <source media="(min-width:992px)" srcSet={property_details_similar_property_id}/>
                        <source media="(min-width:768px)" srcSet={property_details_similar_property_id}/>
                        <img src={area_guide.Tile_Image.url} alt=""/>
                        <strong className="play-btn-icon" onClick = { e => {setPlay(true)}}>
                            <i className="icon-play-btn"></i>
                      </strong>
                    </picture> */}
                </div>
               </div>
            </Col>
            <Col xl={6}>
                 <div className="animated"> 
                  <div className="video-area-content">
                    {/* <span className="area-name">{area_guide.Name} Community</span> */}
                    <h2>{area_guide.Name}</h2>
                    
                    <p>{HTMLReactParser(area_guide.Content)}</p>
                    <Link className="btn btn-primary" to={'/about/our-communities/'+area_guide.URL}><span>View Community</span></Link>
                  </div>
                 </div>
            </Col>
          </Row>
        </Container>
        <PlayVideo
          isOpen = { isPlay } 
          isCloseFunction = { setPlay }
          videoId="luZRKtAUb2A"
          isAutoPlay={1}
        />
      </div>
    </React.Fragment>
    )
}

export default VideoArea